import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const setToken = (state, { token }) => { state.token = token }

const isAuth = state => !!state.token

const login = ({ commit }, { token }) => {
  localStorage.setItem('token', token)
  commit('setToken', { token })
}

const logout = ({ commit }) => {
  localStorage.removeItem('token')
  commit('setToken', { token: '' })
}

const vuexLocal = new VuexPersistence({ storage: window.localStorage })

export default createStore({
  namespaced: true,
  state () {
    return {
      token: localStorage.getItem('token') || ''
    }
  },
  plugins: [
    vuexLocal.plugin
  ],
  mutations: {
    setToken
  },
  getters: {
    isAuth
  },
  actions: {
    login,
    logout
  }
})
